
function menuToJSON(menu, obj) {
	for(var i=0;i<=menu.children.length;i++) {
		var data = {}
		var li = menu.children[i];

		if(li) {
			var a = li.querySelector('a');
			var ul = li.querySelector('ul')

			data.text = a ? a.innerText : '';
			data.href = a ? a.getAttribute('href') : undefined;
			data.active = li.classList.contains('active')
			data.top = null;
			data.height = null;

			if(ul) {
				data.dropdown = [];

				menuToJSON(ul, data.dropdown);
			}

			obj.push(data)
		}

	}

}
