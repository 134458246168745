(function () {
	var menu = document.querySelector('.segura-content__ul');
	var DATA = [];
	var topo = document.querySelector('.topo')
	var EventBus = new Vue();

	menuToJSON(menu, DATA);

	Vue.component('subcategorias', {
		template: `
		<ul
			class="dropdown-subcategorias"
			:style="'--y:'+top+'px'"
		>
			<li
				v-for="(sub, s) in subcategorias"
			>
				<a :href="sub.href">{{sub.text}}</a>
			</li>
		</ul>`,
		data() {
			return {
				subcategorias: null,
				top: 0
			}
		},
		mounted() {
			let el  = document.querySelector('.dropdown-subcategorias');
			let top = 0;

			EventBus.$on('set.subcategorias', event => {
				top = event.top || 0;

				this.subcategorias = event.subcategorias;

				this.top = top;

				// $('.dropdown-subcategorias').css({
				// 	'--y': top+'px'
				// })
			})


			let check = () => {

				let logicalArea = this.height + this.top;

				if(!el) {
					el  = document.querySelector('.dropdown-subcategorias');
				}

				if(el) {
					let rect = el.getBoundingClientRect();

					this.height = rect.height;

					logicalArea = rect.top + rect.height;

					if(logicalArea >= window.innerHeight) {
						let offset = logicalArea - window.innerHeight;

						top = top - offset;

						this.top = top;
					}
				}

				requestAnimationFrame(check)
			}

			check();
		}
	});

	var MENU = Vue.component('VMENU', {
		template: `<nav class="menu">
			<!-- =============================================
			MOBILE
			==================================================-->
			<ul
				v-if="mobile"
				class="segura-content__ul"
			>
				<li
					v-for="(menu,i) in menus"
					:key="i"
					:class="{
						'active': menu.active,
						'dropdown': menu.dropdown
					}"
				>
					<a :href="menu.href">{{menu.text}}</a>

					<!-- Categorias-->
					<ul
						v-if="menu.dropdown"
						class="dropdown-menu"
					>
						<li
							v-for="(categoria,c) in menu.dropdown"
							:key="c"
							:class="{
								'active': categoria.active,
								'dropdown': categoria.dropdown
							}"
						>
							<a :href="categoria.href">{{categoria.text}}</a>

							<ul
								v-if="categoria.dropdown"
								class="dropdown-menu"
							>
								<li
									v-for="(sub, s) in categoria.dropdown"
									:key="s"
									:class="{
										'active': sub.active
									}"
								>
									<a :href="sub.href">{{sub.text}}</a>
								</li>
							</ul>
						</li>
					</ul>
				</li>
			</ul>

			<!--=============================================================
			DESKTOP
			=================================================================-->
			<div
				v-else
				@mouseleave="resetDesktop"
				class="menu__desktop"
			>
				<ul class="segura-content__ul">
					<li
						v-for="(menu,i) in menus"
					   	:key="i"
					   	:class="{
						   'active': menu.active || i === categorias.categoria,
						   'dropdown': menu.dropdown,
						   'dropdown--hover': menuActive == i || i === categorias.categoria
					    }"
						@mouseenter="setCategoria(menu, i)"
					>
					    <a :href="menu.href">{{menu.text}}</a>
					</li>
				</ul>

				<ul
					class="dropdown-categorias"
					v-if="categorias.categoria"
				>
					<li
						v-for="(categoria, c) in categorias.dropdown"
						@mouseenter="setSubcategoria(categoria, c)"
						:class="{
							'dropdown--hover': subcategorias.subcategoria === c
						}"
					>
						<a :href="categoria.href">{{categoria.text}}</a>
					</li>
				</ul>

				<subcategorias></subcategorias>

			</div>
		</nav>`,
		methods: {
			setCategoria(menu, i) {
				if(menu.dropdown) {
					this.menuActive = i;
					this.categorias = {
						categoria: i,
						dropdown: menu.dropdown
					};
				}else {
					this.resetDesktop();
				}
			},
			setSubcategoria(sub, c) {
				let top = event.target.getBoundingClientRect().top - topo.offsetHeight;

				if(sub.dropdown) {

					this.subcategorias = {
						subcategoria: c,
						dropdown: sub.dropdown
					};
				}else {
					this.subcategorias = {
						subcategoria: null,
						dropdown: null
					};
				}

				EventBus.$emit('set.subcategorias', {
					subcategorias: sub.dropdown,
					top: top
				})
			},
			resetDesktop() {
				this.menuActive = this.categoriaAtiva = null;
				this.categorias = {
					categoria: null,
					dropdown: null
				};
				this.subcategorias = {
					subcategoria: null,
					dropdown: null
				};

				EventBus.$emit('set.subcategorias', {
					subcategorias: null,
					top: 0
				})
			}
		},
		data() {
			return {
				mobile: false,
				menus: DATA,
				menuActive: null,
				categoriaAtiva: null,
				categorias: {
					categoria: null,
					dropdown: null
				},
				subcategorias: {
					subcategoria: null,
					dropdown: null
				}
			}
		},
		mounted() {
			let check = ()=> {
				this.mobile = midiaSize == 'xs' || midiaSize == 'sm' || midiaSize == 'md'

				requestAnimationFrame(check)
			}

			check();
		}
	})

	new Vue({
		render: h => h(MENU)
	}).$mount('.segura-content__ul');
})();
